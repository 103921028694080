<template>
  <div>
    <v-card width="390" style="height: auto;">
      <!-- <v-img contain class="white--text align-end" :src="imageUrl"></v-img> -->
      <!-- <v-card-subtitle>Click to view</v-card-subtitle> -->
      <v-card-actions>
        <v-btn block small :href="imageUrl" target="_blank" class="mb-2" color="grey lighten-3">Click to open in new tab</v-btn>
      </v-card-actions>
      <v-card-text>
        <embed :src="imageUrl" width="350px" height="500px" />
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  name: 'ImagePreview',
  components: {},
  props: {
    imageUrl: {
      type: String,
    },
    title: {
      type: String,
      required: true,
    },
    showDownload: {
      type: Boolean,
      required: false,
      deault: false,
    },
  },
  created() {},
  data: () => ({
    completeIcon: 'mdi-check',
    completeColor: 'success',
    notDoneIcon: 'mdi-dots-horizontal',
    notDoneColor: 'grey',
  }),
  computed: {
    type: function() {
      const image = new Image();
      image.src = this.imageUrl;
      console.log('type: ', image);
      console.log('type: ' + image['type']);
      return image.type;
    },
  },
  methods: {
    handleClick() {
      const image = new Image();
      image.src = this.imageUrl;

      const w = window.open('');
      w.document.write(image.outerHTML);
    },
  },
};
</script>

<style scoped></style>
